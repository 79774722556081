@media (max-width: 768px) {
  .MapHelp {
    display: none !important;
  }
}

.MapUkr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2vh 0;
}

.map-pic {
  flex: 1;
  margin: 1vh 1vw;
}

.map-info {
  flex: 1;
  text-align: left;
  height: 100%;
  min-height: 50vh;
  background-color: #f5f5f0;
  padding: 4vh;
  margin: 0 1vh;
  border-radius: 4px;
}

.projects-info {
  height: 50vh;
  overflow-y: auto;
}

.map-info p {
  margin: 0;
}

.map-info h3 {
  text-transform: uppercase;
  margin: 0 0 2vh 0;
}

.map-info h2 {
  margin: 2vh 0;
  text-transform: uppercase;
}

.project-item {
  padding: 2vh 0;
  display: block;
  border-bottom: 1px solid #83868b;
}

.card-project h4 {
  text-transform: uppercase;
}

.project-item:nth-child(1) {
  border-top: 1px solid #83868b;
}

.project-item img {
  width: 200px;
  margin: 1vh 0;
}

.project-info {
  display: flex;
}

.project-info p {
  padding: 1vh 2vw;
}
.project-info img {
  border: 1px solid #83868b;
}

.svg-map {
  stroke: white !important;
}

.svg-map path {
  fill: #4c94ff;
}

#kherson,
#odessa,
#dnipropetrovsk,
#kharkiv,
#mykolaiv {
  fill: #ffd600;
}

.svg-map path:hover,
#kherson:hover,
#odessa:hover,
#dnipropetrovsk:hover,
#kharkiv:hover,
#mykolaiv:hover {
  fill: #95bfff;
}

.svg-map path:active,
#kherson:active,
#odessa:active,
#dnipropetrovsk:active,
#kharkiv:active,
#mykolaiv:active {
  fill: #95bfff;
}

.svg-map path:focus,
#kherson:focus,
#odessa:focus,
#dnipropetrovsk:focus,
#kharkiv:focus,
#mykolaiv:focus {
  fill: #95bfff;
}

.ukraine {
  font-size: 36px;
  font-weight: 600;
}
.svg-map {
  position: relative;
  z-index: 1;
}

#kyivName {
  position: absolute;
  z-index: 20;
  font-size: 18px;
  fill: white;
  color: white;
  text-shadow: -2px 4px 4px #000000;
}

.map-ukraine-wrap {
  padding: 40px 0;
}

.map-proj-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btn-proj-map {
  width: 170px;
  margin: 1vh 2vw;
}
