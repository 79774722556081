body {
  margin: 0;
  font-family: "Inter", sans-serif;
}

button {
  font-size: 16px !important;
}

p {
  color: #83868b;
  font-size: 14px;
}

.btn-req-support {
  background: transparent;
  border: 1px solid #4c94ff;
  color: #4c94ff;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 400;
  padding: 10px;
  width: 100%;
  margin-right: 2vh;
}

.btn-take-part {
  background: #4c94ff;
  border: 1px solid #4c94ff;
  border-radius: 6px;
  cursor: pointer;
  padding: 10px;
  color: #ffd600;
  width: 100%;
  margin-left: 2vh;
}

.open-form {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.close-form {
  display: none;
}

.blur-background {
  filter: blur(5px);
}
.about-us-block {
flex: 1
}
.about-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
}