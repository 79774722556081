:root {
  --blue-main: #4c94ff;
}

@media (max-width: 576px) {
  .block-container:nth-child(2),
  .report-item:nth-child(2) {
    border-top: none !important;
    border-bottom: none !important;
    border-right: none !important;
    border-left: none !important;
  }
  .card-project {
    margin: 2vh 0 !important;
    max-width: 80% !important;
  }
}

@media (max-width: 992px) {
  .help-pictures-second {
    display: none !important;
  }
  .help-pictures-first img {
    display: block !important;
    width: 100% !important;
  }

  @media (max-width: 768px) {
    .block-container:nth-child(2) {
      border: none !important;
    }

    .our-Team p {
      width: 100% !important;
    }
  }

  .help-info {
    text-align: center !important;
    justify-content: center !important;
    max-width: 100% !important;
  }

  .help-block,
  .help-info {
    margin: 0 !important;
    padding: 0 !important;
  }

  .card-project,
  .projects-cards {
    max-width: 100vw !important;
    padding: 0 7.5px;
  }
}

.main {
  margin-top: 3vh;
}

.section-title-text {
  color: var(--blue-main);
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
}

.title-line {
  background-color: var(--blue-main);
  height: 2px;
  width: 56px;
  margin-right: 2vw;
}

.block-container {
  padding: 0 2vh !important;
}

.block-title {
  color: #272727;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.block-text {
  padding: 1vh 0;
}

.block-container:nth-child(2) {
  border-right: 1px solid #83868b;
  border-left: 1px solid #83868b;
}

.our-Team-info {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.our-Team p {
  position: relative;
  width: 70%;
}

.parters {
  text-align: center;
}

.help-block {
  display: flex;
  flex-wrap: wrap;
}

.help-block img {
  padding: 1vh;
}

.help-pictures-first img {
  height: 40vh;
  object-fit: cover;
  object-position: 0 -0vh;
  width: 610px;
  border-radius: 14px;
  display: none;
}

.help-pictures-second {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  height: auto;
  width: 100%;
}
.help-pictures-second img {
  border-radius: 14px;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.help-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5vh;
}

.help-list {
  display: flex;
  align-items: center;
  color: var(--blue-main);
}

.help-list h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
}

.connection-block img {
  position: relative;
  z-index: 1;
}

.connection-left img {
  width: 100%;
  height: 65vh;
  overflow: hidden;
  object-fit: cover;
}
.connection-right img {
  width: 100%;
  height: 30vh;
  overflow: hidden;
  object-fit: cover;
}
.connection-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.connect-title {
  position: absolute;
  color: #f5f5f0;
  z-index: 2;
  margin-left: 2vh;
}

.connect-title h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.connect-title p {
  color: #f5f5f0;
}

.connect-title > .title-line {
  margin: 1vh 0;
}

.connect-title-big {
  top: 85%;
}

#connect-title-first {
  top: 32%;
}

#connect-title-second {
  top: 85%;
}

.help-btns {
  display: flex;
}

.help-btns button {
  margin: 2vh;
}

.Reports a {
  text-decoration: none;
}

.reports-block img {
  border-radius: 4px;
}

.reports-left-side {
  margin-right: 2vw;
}

.reports-right-side {
  background-color: #f5f5f0;
  padding: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.report-item {
  display: flex;
  align-items: center;
  padding: 2vh;
}

.report-item:nth-child(2) {
  border-top: 1px solid #83868b;
  border-bottom: 1px solid #83868b;
}

.report-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  height: 100%;
}

.date-post-main {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-end;
}
.date-post-wrap {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.title-link-report {
  flex: 1;
}

.date-post-main img {
  width: 24px;
}

.report-info .label-report {
  display: flex;
  font-size: 14px;
  align-items: center;
}

.label-report a {
  color: var(--blue-main);
  font-weight: 400;
  line-height: 15px;
}

.date-post {
  color: #83868b;
  margin-top: 1vh;
  display: flex;
}

.date-post h3 {
  text-align: left;
  margin: 0;
}

.image-report img {
  height: 200px;
  object-fit: cover;
  object-position: 0 10px;
}

.btn-more {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.data-wrap {
  display: flex;
  align-items: center;
}
