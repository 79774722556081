@media (max-width: 768px) {
  .head-btns {
    display: block;
  }

  .head-btns button {
    width: 100%;
    margin: 1vh 0;
  }
}
