@media (max-width: 768px) {
  .MapReport {
    display: none;
  }

  .slider-report {
    display: none;
  }

  .statistic-block {
    border: 1px solid #4c94ff;
    border-radius: 4px;
  }

  .statistic-wrap {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .stat-item {
    margin: 1vh 0 !important;
    max-width: 100vw !important;
    border: none !important;
  }

  .pics-reports {
    width: 100% !important;
    padding: 0;
  }

  .revers {
    flex-direction: column-reverse;
  }
}

.slider-report {
  padding: 5vh;
}

.slider-report img {
  height: 30vh;
  width: 40vw;
  object-fit: cover;
  object-position: 0 0;
}

.report-slider-item {
  display: flex;
  width: 70%;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  justify-content: center;
  align-items: center;
}

.carousel-control-prev-icon img {
  width: 50px;
  height: 50px;
}

.report-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 2vw;
}

.report-info a {
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  width: auto;
  max-width: 200px;
}

.stat-item {
  height: 152px;
  border: 2px solid #4c94ff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.number-stat {
  color: #4c94ff;
  font-weight: 500;
  font-size: 24px;
}

.name-stat {
  font-weight: 400;
  font-size: 16px;
  color: #83868b;
}

.reports-all {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.reports-all img {
  height: 40vh;
  border-radius: 6px;
}

.report-item-info {
  background-color: #f5f5f0;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3vh 3vw;
  height: auto;
  border-radius: 6px;
}

.stat-item {
  margin: 2vh 2vw;
}

.pics-reports {
  display: flex;
  width: 50%;
  overflow-x: hidden;
  border-radius: 4px;
}

.report-item-info a {
  text-decoration: none;
  font-size: 14px;
  max-width: 200px;
}

.MapUkr {
  display: flex;
}

.map-pic {
  flex: 1;
}

.map-info-report {
  flex: 1;
  text-align: left;
  height: 100%;
  min-height: 50vh;
  background-color: #f5f5f0;
  padding: 4vh;
  margin: 0 1vh;
  border-radius: 4px;
}

.map-info-report img {
  height: 100%;
}

.map-info-report a {
  font-size: 14px;
  text-decoration: none;
  text-align: center;
}

.svg-map {
  position: relative;
  z-index: 1;
}

#kyivNameReport {
  position: absolute;
  z-index: 20;
  font-size: 18px;
  fill: white;
  color: white;
  text-shadow: -2px 4px 4px #000000;
}

.Reportsblock #luhansk,
.Reportsblock #donetsk,
.Reportsblock #zaporizhia,
.Reportsblock #crimea {
  fill: #ffd600;
}

.Reportsblock #luhansk:hover,
.Reportsblock #donetsk:hover,
.Reportsblock #zaporizhia:hover,
.Reportsblock #crimea:hover {
  fill: #95bfff;
}

.Reportsblock #luhansk:active,
.Reportsblock #donetsk:active,
.Reportsblock #zaporizhia:active,
.Reportsblock #crimea:active {
  fill: #95bfff;
}

.Reportsblock #luhansk:focus,
.Reportsblock #donetsk:focus,
.Reportsblock #zaporizhia:focus,
.Reportsblock #crimea:focus {
  fill: #95bfff;
}

.date-post-wrap-page img {
  width: 24px;
  height: auto;
}

.date-post-page {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.location {
  display: flex;
  align-items: center;
}
