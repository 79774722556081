.Header {
  height: 100vh;
  overflow: hidden;
}

h1 {
  font-size: 28px;
  line-height: 34px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 16px;
}
.Header p {
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 24px;
  line-height: 160%;
}

.hero-video {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: 50% 50%;
}

.head-page {
  position: relative;
  z-index: 10;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  text-shadow: rgb(0 0 0 / 50%) 2px 2px 2px;
}

.menu-wrap {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 1.5%,
    rgba(0, 0, 0, 0) 100%
  );
}

.head-info {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.head-btns {
  display: flex;
  justify-content: space-between;
}

.scroll-down {
  height: 50px;
  width: 50px;
  background: rgba(0, 0, 0, 0.61);
  border-radius: 100%;
  align-self: center;
  display: flex;
  justify-content: center;
  margin-bottom: 3vh;
}
